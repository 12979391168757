.toastWrapper {
  border-radius: 4px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.txtContentError {
  color: #262626;

  /* Body/Body 1/Medium */
  font-family: Outfit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */

  margin: 15px 8px 25px;

  @media screen and (max-width: 450px) {
    margin: 0;
    padding-left: 5px;
    text-align: center;
  }
}
